import { css } from '@emotion/css';
import {
  SnackbarOrigin,
  SnackbarProvider,
  SnackbarProviderProps,
  useSnackbar,
} from 'notistack';
import { PropsWithChildren, useCallback } from 'react';

import {
  FeedbackContext,
  FeedbackHook,
  FeedbackPosition,
  ShowFeedbackFn,
} from './feedback.context';
import { createWrappedAlert } from './WrappedAlert';

export const DEFAULT_AUTO_HIDE_DURATION = 3000;
// Overrides more specific style set by notistack
// Context: https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY?node-id=1079:12533#267010532
const containerSpacing = `48px !important`;

const classes: NonNullable<SnackbarProviderProps['classes']> = {
  containerAnchorOriginBottomCenter: css({ bottom: containerSpacing }),
  containerAnchorOriginBottomRight: css({
    bottom: containerSpacing,
    right: containerSpacing,
  }),
  containerAnchorOriginBottomLeft: css({
    bottom: containerSpacing,
    left: containerSpacing,
  }),
  containerAnchorOriginTopCenter: css({ top: containerSpacing }),
  containerAnchorOriginTopLeft: css({
    top: containerSpacing,
    left: containerSpacing,
  }),
  containerAnchorOriginTopRight: css({
    top: containerSpacing,
    right: containerSpacing,
  }),
};

const Components: NonNullable<SnackbarProviderProps['Components']> = {
  error: createWrappedAlert('error'),
  success: createWrappedAlert('success'),
  warning: createWrappedAlert('warning'),
  'info-high': createWrappedAlert('info-high'),
  'info-low': createWrappedAlert('info-low'),
};

function positionToAnchorOrigin(position: FeedbackPosition): SnackbarOrigin {
  const [vertical, horizontal] = position.split('-') as [
    SnackbarOrigin['vertical'],
    SnackbarOrigin['horizontal'],
  ];
  return { vertical, horizontal };
}

function ProvideFeedback({ children }: PropsWithChildren<unknown>) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const showFeedback = useCallback<ShowFeedbackFn>(
    (
      message,
      { position = 'bottom-center', variant = 'error', ...snackbarOpts } = {}
    ) => {
      return enqueueSnackbar({
        message,
        anchorOrigin: positionToAnchorOrigin(position),
        variant,
        // default autoHideDuration for errors to 6 seconds to give users more time to read the message
        autoHideDuration:
          variant === 'error' && snackbarOpts.autoHideDuration === undefined
            ? 6000
            : snackbarOpts.autoHideDuration,
        ...snackbarOpts,
      });
    },
    [enqueueSnackbar]
  );

  const createErrorFeedback: FeedbackHook['createErrorFeedback'] = (
    message,
    options = {}
  ) => {
    return () => showFeedback(message, { variant: 'error', ...options });
  };

  const createSuccessFeedback: FeedbackHook['createSuccessFeedback'] = (
    message,
    options = {}
  ) => {
    return () => showFeedback(message, { variant: 'success', ...options });
  };

  const createWarningFeedback: FeedbackHook['createWarningFeedback'] = (
    message,
    options = {}
  ) => {
    return () => showFeedback(message, { variant: 'warning', ...options });
  };

  return (
    <FeedbackContext.Provider
      value={{
        showFeedback,
        createErrorFeedback,
        createSuccessFeedback,
        createWarningFeedback,
        hideFeedback: closeSnackbar,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export type FeedbackProviderProps = Partial<SnackbarProviderProps>;
export function FeedbackProvider({
  children,
  ...snackbarProviderProps
}: FeedbackProviderProps) {
  return (
    <SnackbarProvider
      // Max of 2 design ask: https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY?node-id=1079:12533#267010532
      maxSnack={2}
      autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
      Components={Components}
      classes={classes}
      {...snackbarProviderProps}
    >
      <ProvideFeedback>{children}</ProvideFeedback>
    </SnackbarProvider>
  );
}
